.App {
    .introduction {
        max-width: 960px;
        margin: 4rem auto 0;

        // Mobile
        @media (max-width: 767px) {
            margin: 2rem auto 0;
            padding: 0 2rem;
        }

        .logo {
            width: 280px;
            height: auto;

            // Mobile
            @media (max-width: 767px) {
                width: 250px;
            }
        }

        .copy {
            h1 {
                font-size: 1.5rem;
                font-weight: 400;
                margin: 1rem 0;
                line-height: 1.8;
                color: #4F4F4F;

                span {
                    color: #DF7861;
                    background-color: #FCF8E8;
                    padding: 0.1rem .5rem;
                    border-radius: 6px;
                }

                // Mobile
                @media (max-width: 767px) {
                    font-size: 1.45rem;
                    margin: .8rem 0;
                }
            }
        }
    }
}